import { styled } from '@mui/joy/styles';
import LinearProgress from '@mui/joy/LinearProgress';

// loader style
const LoaderWrapper = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 2001,
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
}));

const Loader = () => (
    <LoaderWrapper>
      <LinearProgress size="sm" color="primary" variant="solid"/>
    </LoaderWrapper>
  );
  
  export default Loader;