import React from 'react'
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs'

import { Outlet } from 'react-router-dom';

export default function RootLayout() {
    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline />
            <Box sx={{ display: 'flex', minHeight: '100dvh', }}>
                <Header />
                <Sidebar />
                <Box
                    component="main"
                    sx={{
                        px: { xs: 2, md: 3 },
                        pt: {
                            xs: 'calc(12px + var(--Header-height))',
                            sm: 'calc(12px + var(--Header-height))',
                            md: 3,
                        },
                        pb: { xs: 2, sm: 2, md: 3 },
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '100dvh',
                        gap: 1,
                        backgroundColor: '#FAFAFB',
                        overflow: "hidden",
                        overflowY: "scroll",
                    }}
                >
                    <HeaderBreadcrumbs />
                    <Outlet />
                </Box>
            </Box>
        </CssVarsProvider>
    )
}