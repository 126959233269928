import { lazy, Suspense  } from 'react';
import Loadable from './components/Loadable';
import './App.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import RootLayout from './layouts/RootLayout';
import RequireAuth from './components/RequireAuth';
import Login from './pages/Login';

import {
    createBrowserRouter,
    Route,
    createRoutesFromElements,
    RouterProvider
} from 'react-router-dom';

const Vendor = Loadable(lazy(() => import('./pages/Vendor')));
const User = Loadable(lazy(() => import('./pages/User')));
const ItemCode = Loadable(lazy(() => import('./pages/ItemCode')));
const IQC_Result = Loadable(lazy(() => import('./pages/IQC_Result')));
const IQC_List = Loadable(lazy(() => import('./pages/IQC_List')));
const IQC_Detail = Loadable(lazy(() => import('./pages/IQC_Detail')));
const PQC_Result = Loadable(lazy(() => import('./pages/PQC_Result')));
const PQC_Detail = Loadable(lazy(() => import('./pages/PQC_Detail')));
const PQC_List = Loadable(lazy(() => import('./pages/PQC_List')));
const OQC_Detail = Loadable(lazy(() => import('./pages/OQC_Detail')));
const OQC_Result = Loadable(lazy(() => import('./pages/OQC_Result')));
const OQC_List = Loadable(lazy(() => import('./pages/OQC_List')));
const ItemCodePerVendor = Loadable(lazy(() => import('./pages/ItemCodePerVendor')));
const WorkAreaCode = Loadable(lazy(() => import('./pages/WorkAreaCode')));
const InspectItem = Loadable(lazy(() => import('./pages/InspectItem')));
const TrendChart = Loadable(lazy(() => import('./pages/TrendChart')));

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="login" element={<Login />} />
            <Route element={<RequireAuth />}>
                <Route path="/" element={<RootLayout />}>
                    <Route path="itemCode" element={<ItemCode />} />
                    <Route path="user" element={<User />} />
                    <Route path="vendor" element={<Vendor />} />
                    <Route path="itemCodePerVendor" element={<ItemCodePerVendor />} />
                    <Route path="workAreaCode" element={<WorkAreaCode />} />
                    <Route path="inspectItem" element={<InspectItem />} />
                    <Route path="iqc" element={<IQC_Result />} />
                    <Route path="iqc_list" element={<IQC_List />} />
                    <Route path="iqc_list/:qrCode" element={<IQC_Detail />} />
                    <Route path="pqc" element={<PQC_Result />} />
                    <Route path="pqc_list" element={<PQC_List />} />
                    <Route path="pqc_list/:qrCode" element={<PQC_Detail />} />
                    <Route path="oqc" element={<OQC_Result />} />
                    <Route path="oqc_list" element={<OQC_List />} />
                    <Route path="oqc_list/:qrCode" element={<OQC_Detail />} />
                    <Route path="trendChart/" element={<TrendChart />} />
                </Route>
            </Route>
        </>
    )
)

function App() {
    return (
        <RouterProvider router={router} />
    );
}

export default App;