import React, { useState } from 'react'
import { confirm } from "devextreme/ui/dialog"
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import AddchartRoundedIcon from '@mui/icons-material/AddchartRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

import { logout } from "../features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { closeSidebar } from '../utils';
import { Link, useLocation } from "react-router-dom";
import { admin, vendor } from '../menuItems'

function Toggler({defaultExpanded = true, renderToggle, children}) {
    const [open, setOpen] = useState(defaultExpanded);
    
    return (
        <>
            {renderToggle({ open, setOpen })}
            <Box
                sx={{
                display: 'grid',
                gridTemplateRows: open ? '1fr' : '0fr',
                transition: '0.2s ease',
                '& > *': {
                    overflow: 'hidden',
                },
                }}
            >
                {children}
            </Box>
        </>
    );
}

function Sidebar() {
    const dispatch = useDispatch();
    const location = useLocation();
    const userInfo = useSelector((state) => state.auth.userInfo);

    function handleLogout() {
        var result = confirm("로그아웃 하시겠습니까?", "Confirm");
        result.done(function (dialogResult) {
            if (dialogResult) {
                dispatch(logout());
            }
        });
    }
    
    return (
        <Sheet
            className="Sidebar"
            sx={{
                fontWeight: 500,
                position: { xs: 'fixed', md: 'sticky' },
                transform: {
                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                    md: 'none',
                },
                transition: 'transform 0.4s, width 0.4s',
                zIndex: 100,
                height: '100dvh',
                width: 'var(--Sidebar-width)',
                top: 0,
                p: 2,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRight: '1px solid',
                borderColor: 'divider',
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                ':root': {
                    '--Sidebar-width': '220px',
                    [theme.breakpoints.up('lg')]: {
                    '--Sidebar-width': '240px',
                    },
                },
                })}
            />
            <Box
                className="Sidebar-overlay"
                sx={{
                    position: 'fixed',
                    zIndex: 9998,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    opacity: 'var(--SideNavigation-slideIn)',
                    backgroundColor: 'var(--joy-palette-background-backdrop)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }}
                onClick={() => closeSidebar()}
            />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <IconButton variant="soft" color="primary" size="sm">
                    <QueryStatsRoundedIcon />
                </IconButton>
                <Typography level="title-lg" sx={{fontWeight: 600, color:'#00137F', fontFamily:'Playfair Display'}}>QMS DSNL</Typography>
            </Box>
            <Box
                sx={{
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    [`& .${listItemButtonClasses.root}`]: {
                        gap: 1.5,
                    },
                }}
            >
                <List
                    size="sm"
                    sx={{
                        gap: 1,
                        '--List-nestedInsetStart': '30px',
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                    }}
                >
                    {
                        //userInfo.bizCode === 'E0000' ? //E0000
                        userInfo.isAdmin ? //E0000
                            <ListItem nested>
                                <Toggler
                                    renderToggle={({ open, setOpen }) => (
                                        <ListItemButton onClick={() => setOpen(!open)}>
                                            <SettingsRoundedIcon />
                                            <ListItemContent>
                                                <Typography level="title-sm">{admin.title}</Typography>
                                            </ListItemContent>
                                            <KeyboardArrowDownIcon
                                                sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                                            />
                                        </ListItemButton>
                                    )}
                                >
                                    <List sx={{ gap: 0.5, mt: 0.5 }}>
                                    {
                                        admin.children.map((children) => {
                                            return (
                                                <ListItem key={children.id}>
                                                    <ListItemButton selected={children.url===location.pathname} component={Link} to={children.url} sx={{fontSize:'13px'}}>
                                                        {children.title}
                                                    </ListItemButton>
                                                </ListItem>
                                            )
                                        })
                                    }
                                    </List>
                                </Toggler>
                            </ListItem>
                         : ""
                    }
                    <ListItem nested>
                        <Toggler
                            renderToggle={({ open, setOpen }) => (
                                <ListItemButton onClick={() => setOpen(!open)}>
                                    <AddchartRoundedIcon />
                                    <ListItemContent>
                                        <Typography level="title-sm">{vendor.title}</Typography>
                                    </ListItemContent>
                                    <KeyboardArrowDownIcon
                                        sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                                    />
                                </ListItemButton>
                            )}
                        >
                            <List sx={{ gap: 0.5, mt: 0.5 }}>
                            {
                                vendor.children.map((children) => {
                                    return (
                                        <ListItem key={children.id}>
                                            <ListItemButton selected={children.url.split("/")[1]===location.pathname.split("/")[1]} component={Link} to={children.url} sx={{fontSize:'13px'}}>
                                                {children.title}
                                            </ListItemButton>
                                        </ListItem>
                                    )
                                })
                            }
                            </List>
                        </Toggler>
                    </ListItem>
                </List>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Avatar
                    variant="outlined"
                    size="sm"
                ><AccountCircleRoundedIcon/></Avatar>
                <Box sx={{ minWidth: 0, flex: 1 }}>
                    <Typography fontWeight={700} sx={{overflow: "hidden", textOverflow: "ellipsis", fontSize:'13px'}}>{userInfo?.name}</Typography>
                    <Typography level="body-xs" sx={{overflow: "hidden", textOverflow: "ellipsis"}}>{userInfo?.email}</Typography>
                </Box>
                <IconButton size="sm" variant="plain" color="neutral" onClick={handleLogout}>
                    <LogoutRoundedIcon />
                </IconButton>
            </Box>
        </Sheet>
    )
}

export default Sidebar