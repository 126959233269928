const admin = {
    id: 'admin',
    title: "Admin",
    children: [
        {
            id: 'vendor',
            title: '공급처정보 관리',
            url: '/vendor'
        },
        {
            id: 'user',
            title: '사용자정보 관리',
            url: '/user',
        },
        {
            id: 'itemcode',
            title: '품목정보 관리',
            url: '/itemCode',
        },
        {
            id: 'wacode',
            title: '공급처별 공정관리',
            url: '/workAreaCode',
        },
        {
            id: 'vendoritem',
            title: '품목정보 연동',
            url: '/itemCodePerVendor',
        },        
        {
            id: 'inspectitem',
            title: '품목별 검사항목 등록',
            url: '/inspectItem',
        },
        {
            id: 'controlChart',
            title: 'Control Chart',
            url: '/trendChart',
        },
    ]
};

const vendor = {
    id : 'inspection',
    title : 'Inspection',
    children: [
        {
            id: 'iqc',
            title: 'IQC 검사등록',
            url: '/iqc',
        },
        {
            id: 'iqcList',
            title: 'IQC 검사조회',
            url: '/iqc_list',
        },
        {
            id: 'pqc',
            title: 'PQC 검사등록',
            url: '/pqc',
        },
        {
            id: 'pqcList',
            title: 'PQC 검사조회',
            url: '/pqc_list',
        },
        {
            id: 'oqc',
            title: 'OQC 검사등록',
            url: '/oqc',
        },
        {
            id: 'oqcList',
            title: 'OQC 검사조회',
            url: '/oqc_list',
        },
    ]
};

export { admin, vendor };