import React, { useState, useRef, useEffect } from 'react'
import { alert } from "devextreme/ui/dialog"
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import Avatar from '@mui/joy/Avatar';
import LockOutlined from '@mui/icons-material/LockOutlined';

import { useNavigate, useLocation } from 'react-router-dom'
import { login } from "../features/authSlice";
import { useDispatch } from "react-redux";

import { useCookies } from "react-cookie";
import useAxios from '../hooks/useAxios';

const Login = () => {
    const [response, error, loading, axiosFetch] = useAxios();
    const [loadingLogin, setLoadingLogin] = useState(false);
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/';

    const [cookies, setCookie, removeCookie] = useCookies(["rememberUserId"]);
    const [details, setDetails] = useState({
        email: '',
        password: '',
        remember: false,
    });

    const [submit, setSubmit] = useState(0);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        setDetails((prev) => {
            return {...prev, [name] : value };
        });
    }

    // password focus 및 user id setting
    const passwordField = useRef(null);
    useEffect(() => {
    	/*저장된 쿠키값이 있으면, CheckBox TRUE 및 UserID에 값 셋팅*/
        if (cookies.rememberUserId !== undefined) {
            setDetails((prev) => {
                return {...prev, email : cookies.rememberUserId, remember: true };
            });
            
            passwordField.current.children[0].focus();
        }
    }, []);

    useEffect(() => {
        if (!!error && !loading)
        {
            if (error.code == 'ERR_NETWORK') {
                alert('서비스에 연결할 수 없습니다!', 'Alert');
            } else {
                var result = alert(error.response.data.error.message, 'Alert');
                result.done(function () {
                    passwordField.current.children[0].focus();
                });
            }

            setLoadingLogin(false);

            return;
        }

        if (submit > 0) { // 최초한번 실행방지
            // 쿠키처리
            if (details.remember) {
                setCookie('rememberUserId', details.email);
            }
            else {
                removeCookie("rememberUserId");
            }

            const userInfo = { email: response.UserId, name: response.UserName, bizCode: response.VendorCode, isAdmin: response.IsAdmin}
            dispatch(login(userInfo));

            navigate(from, { replace: true });
        }
    }, [submit]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoadingLogin(true);

        await axiosFetch({
            method:'GET',
            url: '/GetUser',
            requestConfig: {
                params : {id : details.email, password:details.password}
            }
        });
        
        setSubmit((prev) => ++prev);
    }

    return (
        <Box
            component="main"
            sx={{
                mt: 18,
                py: 2,
                pb: 5,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: 350,
                maxWidth: '100%',
                mx: 'auto',
                borderRadius: 'sm',
                '& form': {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                },
                [`& .${formLabelClasses.asterisk}`]: {
                    visibility: 'hidden',
                },
            }}
        >
            <Stack gap={1} alignItems="center">
                <Avatar style={{backgroundColor: '#1bbd7e'}}><LockOutlined/></Avatar>
                <Typography level="h3">Sign in</Typography>
            </Stack>
            <Stack gap={4} sx={{ mt: 2 }}>
                <form onSubmit={handleSubmit}>
                    <FormControl required>
                        <FormLabel>Email</FormLabel>
                        <Input type="email" name="email" value={details.email} onChange={handleChange} autoFocus />
                    </FormControl>
                    <FormControl required>
                        <FormLabel>Password</FormLabel>
                        <Input type="password" name="password" autoComplete="on" onChange={handleChange} ref={passwordField} />
                    </FormControl>
                    <Stack gap={4} sx={{ mt: 2 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Checkbox size="sm" label="Email 저장" name="remember" checked={details.remember} onChange={handleChange}/>
                        </Box>
                        <Button loading={loadingLogin} loadingPosition="start" type="submit" fullWidth>Sign in</Button>
                    </Stack>
                </form>
            </Stack>
        </Box>
    )
}

export default Login