import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useSelector } from "react-redux";

const RequireAuth = () => {
    const isAuth = useSelector((state) => state.auth.isAuthenticated);
    const location  = useLocation();

    return (
        isAuth
            ? <Outlet />
            : <Navigate to="login" state={{ from: location}} replace />
    )
}

export default RequireAuth;