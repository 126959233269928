import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isAuthenticated: false,
    userInfo: {}, // for user object
    userToken: null, // for storing the JWT
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            state.userInfo = action.payload;
            state.isAuthenticated = true;
        },
        logout: (state) => {
            state.userInfo = null;
            state.isAuthenticated = false;
        }
    }
})

export const { login, logout } = authSlice.actions;
export const isAuthenticated = (state) => state.auth.isAuthenticated;
export const userInfo = authSlice;
export default authSlice.reducer;