import React from 'react'
import Box from '@mui/joy/Box';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import AddchartRoundedIcon from '@mui/icons-material/AddchartRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import Typography from '@mui/joy/Typography';
import { admin, vendor } from '../menuItems'
import { useLocation } from "react-router-dom";

const HeaderBreadcrumbs = () => {
    const location = useLocation();
    const menuItems = [admin, vendor];

    let menu;
    let idx;
    for (var i = 0; i < menuItems.length; i++) {
        menu = menuItems[i].children?.find(item => item.url.split("/")[1] === location.pathname.split("/")[1]);
        if (menu !== undefined) {
            idx = i;
            break;
        }
    }

    if (location.pathname === '/') return;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {
                menuItems[idx]?.id === 'admin' ? <SettingsRoundedIcon fontSize="sm"/> : <AddchartRoundedIcon fontSize="sm"/>
            }
            <Breadcrumbs
                size="sm"
                aria-label="breadcrumbs"
                separator={<ChevronRightRoundedIcon fontSize="sm" />}
                sx={{ pl: 0.5 }}
            >
                <Typography fontSize={13} fontWeight={500}>{ menuItems[idx]?.title }</Typography>
                <Typography sx={{color:'#00137F'}} fontSize={13}>{ menu?.title }</Typography>
            </Breadcrumbs>
        </Box>
    )
}

export default HeaderBreadcrumbs